import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function TermsOfUse() {
  return (
    <Layout>
      <section className="content">
        <h1 className="content__heading content__heading--large policy-text--center">
          GS VANLIFE, INC.
          <br />
          TERMS OF USE
        </h1>
        <p className="content__body content__body--no-indent">
          Last Modified: March 2025
        </p>
        <h2 className="content__heading content__heading--bold content__heading--medium">
          Acceptance of the Terms of Use
        </h2>
        <p className="content__body content__body--no-indent">
          These terms of use are entered into by and between You and GS VanLife,
          Inc. (the “<span className="content__body--bold">Company</span>,” “
          <span className="content__body--bold">we</span>,” or “
          <span className="content__body--bold">us</span>”). The following terms
          and conditions, together with any documents they expressly incorporate
          by reference (collectively, “
          <span className="content__body--bold">Terms of Use</span>”), govern
          your access to and use of our Company app and websites at
          https://nicesquirrel.com, including any content, functionality and
          services offered on or through the app and these domains, including
          confirmations using text messages (the “
          <span className="content__body--bold">Website</span>”), whether as a
          guest or a registered user.
        </p>
        <p className="content__body content__body--no-indent">
          Please read the Terms of Use carefully before you start to use the
          Website.{" "}
          <span className="content__body--bold">
            By using the Website or by clicking to accept or agree to the Terms
            of Use when this option is made available to you, you accept and
            agree to be bound and abide by these Terms of Use and our Privacy
            Policy, found at{" "}
            <Link to="/privacy-policy">
              https://nicesquirrel.com/privacy-policy/
            </Link>
            , which is incorporated herein by reference.
          </span>{" "}
          If you do not want to agree to these Terms of Use or the Privacy
          Policy, you must not access or use the Website.
        </p>
        <p className="content__body content__body--no-indent">
          The Website is offered and available to users who are of legal driving
          age in their country of residence. Some or all of the services that we
          provide (the “<span className="content__body--bold">Services</span>”)
          may not be available in your country of residence. By using this
          Website, you represent and warrant that you are of legal age to form a
          binding contract with the Company and that you are of legal driving
          age. If you do not meet all of these requirements, you must not access
          or use the Website.
        </p>
        <h2 className="content__heading content__heading--bold content__heading--medium">
          Changes to the Terms of Use
        </h2>
        <p className="content__body content__body--no-indent">
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them, and apply to all access to and use of the Website thereafter.
          However, any changes to the dispute resolution provisions set out in
          our organizational documents or applicable laws will not apply to any
          disputes for which the parties have actual notice on or before the
          date the change is posted on the Website.
        </p>
        <p className="content__body content__body--no-indent">
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page each time you access this Website so you
          are aware of any changes, as they are binding on you.
        </p>
        <h2 className="content__heading content__heading--medium content__heading--bottom-margin">
          I. TERMS OF USE – WEBSITE:
        </h2>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Accessing the Website and Account Security
        </h3>
        <p className="content__body content__body--no-indent">
          We reserve the right to withdraw or amend the Website, and any service
          or material we provide on the Website, in our sole discretion without
          notice. We will not be liable if for any reason all or any part of the
          Website is unavailable at any time or for any period. From time to
          time, we may restrict access to some parts of the Website, or the
          entire Website, to users, including registered users.
        </p>
        <p className="content__body content__body--no-indent">
          You are responsible for both:
        </p>
        <ul className="content__body">
          <li>
            Making all arrangements necessary for you to have access to the
            Website.
          </li>
          <li>
            Ensuring that all persons who access the Website through your
            internet connection are aware of these Terms of Use and comply with
            them.
          </li>
        </ul>
        <p className="content__body content__body--no-indent">
          To access the Website or some of the resources it offers, you may be
          asked to provide certain registration details or other information. It
          is a condition of your use of the Website that all the information you
          provide on the Website is correct, current and complete. You agree
          that all information you provide to register with this Website or
          otherwise, including, but not limited to, through the use of any
          interactive features on the Website, is governed by our Privacy Policy
          and you consent to all actions we take with respect to your
          information consistent with our Privacy Policy.
        </p>
        <p className="content__body content__body--no-indent">
          If you choose, or are provided with, a user name, password, or any
          other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to this Website or portions of it using your user name,
          password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your user name or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information.
        </p>
        <p className="content__body content__body--no-indent">
          We have the right to disable any user name, password, or other
          identifier, whether chosen by you or provided by us, at any time if,
          in our opinion, you have violated any provision of these Terms of Use.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Intellectual Property Rights
        </h3>
        <p className="content__body content__body--no-indent">
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by the Company, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
        <p className="content__body content__body--no-indent">
          These Terms of Use permit you to use the Website for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store, or transmit any of the material on our
          Website, except as follows:
        </p>
        <ul className="content__body">
          <li>
            Your computer may temporarily store copies of such materials in RAM
            incidental to your accessing and viewing those materials.
          </li>
          <li>
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>
          <li>
            You may print or download one copy of a reasonable number of pages
            of the Website for your own personal, non-commercial use and not for
            further reproduction, publication, or distribution.
          </li>
          <li>
            If we provide desktop, mobile, or other applications for download,
            you may download a single copy to your computer or mobile device
            solely for your own personal, non-commercial use, provided you agree
            to be bound by our end user license agreement for such applications.
          </li>
          <li>
            If we provide social media features with certain content, you may
            take such actions as are enabled by such features
          </li>
        </ul>
        <p className="content__body content__body--no-indent">You must not:</p>
        <ul className="content__body">
          <li>Modify copies of any materials from this site.</li>
          <li>
            Use any illustrations, photographs, video or audio sequences, or any
            graphics separately from the accompanying text.
          </li>
          <li>
            Delete or alter any copyright, trademark or other proprietary rights
            notices from copies of materials from this site
          </li>
        </ul>
        <p className="content__body content__body--no-indent">
          You must not access or use for any commercial purposes any part of the
          Website or any services or materials available through the Website.
        </p>
        <p className="content__body content__body--no-indent">
          If you wish to make any use of material on the Website other than that
          set out in this section, please address your request to:{" "}
          <a href="mailto:info@nicesquirrel.com">info@nicesquirrel.com</a>.
        </p>
        <p className="content__body content__body--no-indent">
          If you print, copy, modify, download or otherwise use or provide any
          other person with access to any part of the Website in breach of the
          Terms of Use, your right to use the Website will stop immediately and
          you must, at our option, return or destroy any copies of the materials
          you have made. No right, title or interest in or to the Website or any
          content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark and other laws.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Trademarks
        </h3>
        <p className="content__body content__body--no-indent">
          The Company name, the Company logo, and certain related names, logos,
          product and service names, designs, and slogans are trademarks of the
          Company or its affiliates or licensors. You must not use such marks
          without the prior written permission of the Company. All other names,
          logos, product and service names, designs and slogans on this Website
          are the trademarks of their respective owners.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Prohibited Uses
        </h3>
        <p className="content__body content__body--no-indent">
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </p>
        <ul className="content__body">
          <li>
            In any way that violates any applicable federal, state, local or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the United
            States or other countries).
          </li>
          <li>
            For the purpose of exploiting, harming or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.
          </li>
          <li>
            To send, knowingly receive, upload, download, use or re-use any
            material that does not comply with the Content Standards set out in
            these Terms of Use.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material without our prior written consent, including
            any “junk mail,” “chain letter,” “spam,” or any other similar
            solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity (including,
            without limitation, by using email addresses or screen names
            associated with any of the foregoing).
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of the Website, or which, as determined by us, may
            harm the Company or users of the Website, or expose them to
            liability.
          </li>
        </ul>
        <p className="content__body content__body--no-indent">
          Additionally, you agree not to:
        </p>
        <ul className="content__body">
          <li>
            Use the Website in any manner that could disable, overburden, damage
            or impair the site or interfere with any other party’s use of the
            Website, including their ability to engage in real time activities
            through the Website.
          </li>
          <li>
            Use any robot, spider or other automatic device, process, or means
            to access the Website for any purpose, including monitoring or
            copying any of the material on the Website.
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on the
            Website, or for any other purpose not expressly authorized in these
            Terms of Use, without our prior written consent.
          </li>
          <li>
            Use any device, software, or routine that interferes with the proper
            working of the Website.
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs or other
            material that is malicious or technologically harmful.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer, or database connected to the
            Website.
          </li>
          <li>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ul>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          User Contributions
        </h3>
        <p className="content__body content__body--no-indent">
          The Website and the app may contain message boards, chat rooms,
          personal web pages or profiles, forums, bulletin boards and other
          interactive features (collectively, “
          <span className="content__body--bold">Interactive Services</span>”)
          that allow users to post, submit, publish, display or transmit to
          other users or other persons (hereinafter, “
          <span className="content__body--bold">post</span>
          ”) content or materials (collectively, “
          <span className="content__body--bold">User Contributions</span>”) on
          or through the Website.
        </p>
        <p className="content__body content__body--no-indent">
          All User Contributions must comply with the Content Standards set out
          in these Terms of Use.
        </p>
        <p className="content__body content__body--no-indent">
          Any User Contribution you post to the site will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Website, you grant us and our affiliates and
          service providers, and each of their and our respective licensees,
          successors, and assigns the right to use, reproduce, modify, perform,
          display, distribute and otherwise disclose to third parties any such
          material, in accordance with your account settings.
        </p>
        <p className="content__body content__body--no-indent">
          You represent and warrant that:
        </p>
        <ul className="content__body">
          <li>
            You own or control all rights in and to the User Contributions and
            have the right to grant the license granted above to us and our
            affiliates and service providers, and each of their and our
            respective licensees, successors and assigns.
          </li>
          <li>
            All of your User Contributions do and will comply with these Terms
            of Use.
          </li>
        </ul>
        <p className="content__body content__body--no-indent">
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          full responsibility for such content, including its legality,
          reliability, accuracy and appropriateness.
        </p>
        <p className="content__body content__body--no-indent">
          We are not responsible or liable to any third party for the content or
          accuracy of any User Contributions posted by you or any other user of
          the Website.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Monitoring and Enforcement; Termination
        </h3>
        <p className="content__body content__body--no-indent">
          We have the right to:
        </p>
        <ul className="content__body">
          <li>
            Remove or refuse to post any User Contributions for any or no reason
            in our sole discretion.
          </li>
          <li>
            Take any action with respect to any User Contribution that we deem
            necessary or appropriate in our sole discretion, including if we
            believe that such User Contribution violates the Terms of Use,
            including the Content Standards, infringes any intellectual property
            right or other right of any person or entity, threatens the personal
            safety of users of the Website or the public, or could create
            liability for the Company.
          </li>
          <li>
            Disclose your identity or other information about you to any third
            party who claims that material posted by you violates their rights,
            including their intellectual property rights or their right to
            privacy.
          </li>
          <li>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the Website.
          </li>
          <li>
            Terminate or suspend your access to all or part of the Website for
            any or no reason, including without limitation, any violation of
            these Terms of Use.
          </li>
        </ul>
        <p className="content__body content__body--no-indent">
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Website. YOU WAIVE AND HOLD
          HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF
          THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
          INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p className="content__body content__body--no-indent">
          However, we cannot and do not undertake to review material before it
          is posted on the Website, and cannot ensure prompt removal of
          objectionable material after it has been posted. Accordingly, we
          assume no liability for any action or inaction regarding
          transmissions, communications, or content provided by any user or
          third party. We have no liability or responsibility to anyone for
          performance or nonperformance of the activities described in this
          section.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Content Standards
        </h3>
        <p className="content__body content__body--no-indent">
          These content standards apply to any and all User Contributions and
          use of Interactive Services. User Contributions must in their entirety
          comply with all applicable federal, state, local and international
          laws and regulations. Without limiting the foregoing, User
          Contributions must not:
        </p>
        <ul className="content__body">
          <li>
            Contain any material that is defamatory, obscene, indecent, abusive,
            offensive, harassing, violent, hateful, inflammatory or otherwise
            objectionable.
          </li>
          <li>
            Promote sexually explicit or pornographic material, violence or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
          </li>
          <li>
            Infringe any patent, trademark, trade secret, copyright or other
            intellectual property or other rights of any other person.
          </li>
          <li>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with these Terms of Use and our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </li>
          <li>Be likely to deceive any person.</li>
          <li>
            Promote any illegal activity, or advocate, promote or assist any
            unlawful act.
          </li>
          <li>
            Cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, alarm or annoy any other person.
          </li>
          <li>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person or organization.
          </li>
          <li>
            Involve commercial activities or sales, such as contests,
            sweepstakes, and other sales promotions, barter or advertising.
          </li>
          <li>
            Give the impression that they emanate from or are endorsed by us or
            any other person or entity, if this is not the case.
          </li>
        </ul>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Copyright Infringement
        </h3>
        <p className="content__body content__body--no-indent">
          If you believe that any User Contributions violate your copyright,
          please send us a notice of copyright infringement. It is the policy of
          the Company to terminate the user accounts of repeat infringers.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Reliance on Information Posted
        </h3>
        <p className="content__body content__body--no-indent">
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness or usefulness of this information. Any reliance
          you place on such information is strictly at your own risk. We
          disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </p>
        <p className="content__body content__body--no-indent">
          This Website may include content provided by third parties, including
          materials provided by other users, bloggers and third-party licensors,
          syndicators, aggregators and/or reporting services. All statements
          and/or opinions expressed in these materials, and all articles and
          responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Changes to the Website
        </h3>
        <p className="content__body content__body--no-indent">
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Information About You and Your Visits to the Website
        </h3>
        <p className="content__body content__body--no-indent">
          All information we collect on this Website is subject to our Privacy
          Policy. By using the Website, you consent to all actions taken by us
          with respect to your information in compliance with the Privacy
          Policy.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Online Purchases and Other Terms and Conditions
        </h3>
        <p className="content__body content__body--no-indent">
          All purchases through our app or the Website, or other transactions
          for the sale of goods, services or information through the Website, or
          resulting from visits to our app or Website made by you, are governed
          by our Terms of Use - Services below.
        </p>
        <p className="content__body content__body--no-indent">
          Additional terms and conditions may also apply to specific portions,
          services or features of the app, the Website or the Services. All such
          additional terms and conditions will be provided during such
          transactions and are hereby incorporated by this reference into these
          Terms of Use.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Linking to the Website and Social Media Features
        </h3>
        <p className="content__body content__body--no-indent">
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it,
          but you must not establish a link in such a way as to suggest any form
          of association, approval, or endorsement on our part without our
          express written consent.
        </p>
        <p className="content__body content__body--no-indent">
          This Website may provide certain social media features that enable you
          to:
        </p>
        <ul className="content__body">
          <li>
            Link from your own or certain third-party websites to certain
            content on this Website.
          </li>
          <li>
            Send emails or other communications with certain content, or links
            to certain content, on this Website.
          </li>
          <li>
            Cause limited portions of content on this Website to be displayed or
            appear to be displayed on your own or certain third-party websites.
          </li>
        </ul>
        <p className="content__body content__body--no-indent">
          You may use these features solely as they are provided by us, solely
          with respect to the content they are displayed with and otherwise in
          accordance with any additional terms and conditions we provide with
          respect to such features. Subject to the foregoing, you must not:
        </p>
        <ul className="content__body">
          <li>Establish a link from any website that is not owned by you.</li>
          <li>
            Cause the Website or portions of it to be displayed on, or appear to
            be displayed by, any other site, for example, framing, deep linking
            or in-line linking.
          </li>
          <li>Link to any part of the Website other than the homepage.</li>
          <li>
            Otherwise take any action with respect to the materials on this
            Website that is inconsistent with any other provision of these Terms
            of Use.
          </li>
        </ul>
        <p className="content__body content__body--no-indent">
          The website from which you are linking, or on which you make certain
          content accessible, must comply in all respects with the Content
          Standards set out in these Terms of Use.
        </p>
        <p className="content__body content__body--no-indent">
          You agree to cooperate with us in causing any unauthorized framing or
          linking immediately to stop. We reserve the right to withdraw linking
          permission without notice.
        </p>
        <p className="content__body content__body--no-indent">
          We may disable all or any social media features and any links at any
          time without notice in our discretion.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Links from the Website
        </h3>
        <p className="content__body content__body--no-indent">
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third-party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Geographic Restrictions
        </h3>
        <p className="content__body content__body--no-indent">
          The owner of the Website is based in the State of Delaware in the
          United States. We provide this Website for use only by persons located
          in the United States. We make no claims that the Website or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Website may not be legal by certain persons or in
          certain countries. If you access the Website from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </p>
        <h2 className="content__heading content__heading--medium content__heading--bottom-margin">
          II. TERMS OF USE – SERVICES:
        </h2>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Payment Platforms
        </h3>
        <p className="content__body content__body--no-indent">
          GS Vanlife, Inc. uses the third-party payment platform, Stripe, Inc.
          (“Stripe”), to process credit and debit card transactions. Renter
          agrees to be bound by Stripe’s Terms of Service. Further information
          about Stripe and its services can be found at
          https://stripe.com/connect.
        </p>
        <p className="content__body content__body--no-indent">
          You expressly understand and agree that all payments and monetary
          transactions are handled by Stripe. You agree that GS Vanlife, Inc.
          shall not be liable for any issues regarding financial and monetary
          transactions between you and any other party, including Stripe.
        </p>
        <p className="content__body content__body--no-indent">
          Users of the Services are expressly prohibited from processing stolen
          credit cards or unauthorized credit cards through Stripe.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Summary Terms of Services
        </h3>
        <p className="content__body content__body--no-indent">
          <em>Terms of the Rental Agreement</em>
        </p>
        <p className="content__body content__body--no-indent">
          Customers are subject to the terms and conditions of the rental
          contract and the laws of the state and country wherein the vehicle is
          rented.
        </p>
        <ul className="content__body">
          <li>All drivers must be at least 21 years of age.</li>
          <li>
            All drivers must possess valid drivers’ license and valid credit
            card.
          </li>
          <li>
            Renter’s identification name must match name on credit card being
            used to pay for rental.
          </li>
          <li>All occupants of the motorhome must wear seatbelts.</li>
          <li>
            Rentals may not be towed or driven to Mexico, Canada, on any beach,
            or off any paved road unless that road is part of a campsite,
            campground or RV park.
          </li>
          <li>Nothing may be towed behind the motorhome.</li>
          <li>Other than cats, pets are allowed.</li>
          <li>
            Nothing is allowed to be attached or affixed to the roof of the
            motorhome.
          </li>
          <li>
            NO SMOKING OF ANY KIND, INCLUDING BURNING INCENSE AND CANDLES IS
            ALLOWED. CLEANING DEPOSIT WILL BE FORFEITED IF THERE IS ANY SMOKING.
          </li>
          <li>
            User stipulates that allowing a cat in the van or smoking in the van
            constitutes willful negligence.
          </li>
        </ul>
        <h4 className="content__heading content__heading--medium">
          Rental period
        </h4>
        <p className="content__body content__body--no-indent">
          One rental day is interpreted as a period of 24 hours. Included in
          this 24 hour period is the time where in you collect and return the
          rental vehicle.
        </p>
        <h4 className="content__heading content__heading--medium">Age</h4>
        <p className="content__body content__body--no-indent">
          The minimum age of a driver is 21 years. Drivers and extra drivers
          above the age of 75 must be in possession of a declaration of health.
        </p>
        <h4 className="content__heading content__heading--medium">
          Renter and Driver Requirements
        </h4>
        <p className="content__body content__body--no-indent">
          Only Authorized Driver(s) are permitted to drive &amp; operate the GS
          Vanlife, Inc. Authorized drivers are defined as only those drivers
          expressly listed and designated as authorized drivers within this
          contract.
        </p>
        <p className="content__body content__body--no-indent">
          Renter relieves GS Vanlife Inc. and its insurance carrier(s) of any
          liability incurred while the motor home is driven or operated by
          anyone other than an authorized driver.
        </p>
        <p className="content__body content__body--no-indent">
          Renter certifies that all the information provided in the Reservation
          Application and in this contact is true and accurate. Any inaccurate
          or fraudulent information voids the rental contract.
        </p>
        <h4 className="content__heading content__heading--medium">
          Pick-up and return
        </h4>
        <p className="content__body content__body--no-indent">
          Pick up is anytime 7 days a week 7am - midnight
        </p>
        <p className="content__body content__body--no-indent">
          At this time, delivery is not available.
        </p>
        <p className="content__body content__body--no-indent">
          There are no refunds for early returns.
        </p>
        <p className="content__body content__body--no-indent">
          At the end of the rental period you are required to return the vehicle
          undamaged and to a designated Squirrel drop off location.
        </p>
        <h4 className="content__heading content__heading--medium">Mileage</h4>
        <p className="content__body content__body--no-indent">
          There are no refunds for unused miles. The first 50 miles per 24 hours
          are complimentary. Additional Mileage Charges: in excess of free miles
          of 50 miles per day, the renter will be charged at: $0.32 per mile for
          next 250 miles; the next 250 are at @ $0.78/mile. All miles beyond
          this are $1.59 per mile.
        </p>
        <p className="content__body content__body--no-indent">
          We may use your Security Deposit to pay any of these amounts owed us.
          Once the debit is made the guest’s credit card will be charged in
          order to replenish the security deposit so the balance stays at $500.
        </p>
        <h4 className="content__heading content__heading--medium">
          Indemnity and warranty
        </h4>
        <p className="content__body content__body--no-indent">
          You agree to indemnify us, defend us, and hold us harmless from all
          claims, liability, costs and attorneys’ fees incurred by us resulting
          from, and arising out of, this rental and your use of the vehicle. We
          make no warranties, express, implied, or apparent, regarding the
          vehicle, and no warranty that the vehicle is fit for a particular
          purpose
        </p>
        <h4 className="content__heading content__heading--medium">
          Roof Access
        </h4>
        <p className="content__body content__body--no-indent">
          For safety reasons, all members of the rental party are expressly
          prohibited from use of the roof. Any evidence of use or access of
          either of these items will result in full forfeiture of the Security
          and Damage Deposit in addition to any damage incurred.
        </p>
        <h4 className="content__heading content__heading--medium">Accidents</h4>
        <p className="content__body content__body--no-indent">
          In case of an accident you have to call the police immediately and
          acquire a full police report. You must inform the rental company as
          soon as practicable of the accident and provide a copy of the police
          report to GS VanLife, Inc. Is there a law about this?
        </p>
        <h4 className="content__heading content__heading--medium">
          Quality Check
        </h4>
        <p className="content__body content__body--no-indent">
          If you are, for whatever reason, not satisfied with your rental
          vehicle, you have to report the issue to us immediately. It is very
          difficult to judge a claim about the state of a vehicle after the
          rental period is over. We must always be informed of any problems to
          enable us to find an appropriate solution.
        </p>
        <h4 className="content__heading content__heading--medium">
          Mechanical problems
        </h4>
        <p className="content__body content__body--no-indent">
          In the case of mechanical problems you must immediately inform us
          custumer care via phone with call to be recorded for quality
          assurance.
        </p>
        <h4 className="content__heading content__heading--medium">
          Rental Rates
        </h4>
        <p className="content__body content__body--no-indent">
          The published rates are correct at the moment of publication. However
          changes in federal, state or local laws and taxes can cause price
          increases. We reserve the right to adjust our published rates as
          needed.
        </p>
        <h4 className="content__heading content__heading--medium">Parking</h4>
        <p className="content__body content__body--no-indent">
          Guest must follow all parking laws. The guest is responsible for the
          costs of all parking tickets, moving violations, towing charges, and
          fines or fees incurred as a result of illegally parking. Renter is
          responsible for late fees if towing leads to delayed return of
          vehicle.
        </p>
        <h4 className="content__heading content__heading--medium">
          Your Property
        </h4>
        <p className="content__body content__body--no-indent">
          You release us, our agents and employees from all claims for loss of,
          or damage to, your personal property or that of any other person. This
          includes any personal property left behind in the squirrel after the
          Squirrel reservation period is over. This Includes vehicles parked at
          Squirrel designated pick-up and drop-off locations.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Summary of Guests Costs
        </h3>
        <p> </p>
        <h4 className="content__heading content__heading--medium">Charges</h4>
        <p className="content__body content__body--no-indent">
          Renter authorizes full payment to be made by any credit reference
          listed on the Reservation.
        </p>
        <p className="content__body content__body--no-indent">
          You agree to pay us for all charges due to us under the terms of your
          rental contract, including, but not limited to: (a) a mileage charge
          based on our experience if the odometer or its seal is tampered with,
          or disconnected; (b) all expenses we incur in locating and recovering
          the vehicle if you fail to return it or if we elect to repossess it
          under the terms of this contract; (c) all costs, including pre- and
          post-judgment attorney’s fees, we incur collecting payment from you or
          otherwise enforcing our rights under this contract; (d) a 2% per month
          late fee, or the maximum allowed by law (if lower than 2%) on all
          amounts due us but not paid upon return of the vehicle; (e) $100, plus
          $5 per mile for every mile between the renting location and the place
          where the motor home is returned or abandoned, plus any additional
          recovery expenses we incur; (f) $25 if your credit card is declined.
        </p>
        <p className="content__body content__body--no-indent">
          We may use your Security Deposit to pay any of these amounts owed us.
          Once the debit is made the guest's credit card will be charged in
          order to replenish the security deposit so the balance stays at $500
        </p>
        <p className="content__body content__body--no-indent">
          Holding deposit of $500 is charged at time of booking.
        </p>
        <p className="content__body content__body--no-indent">
          Each daily payment is charged and is non-refundable 24 hours before
          the start of that daily period.
        </p>
        <p className="content__body content__body--no-indent">
          Guest is responsible for costs (not to exceed the $500 security
          deposit) including but not limited to gasoline, propane, motor oil,
          replacing damaged tires, unlawful disposal of sewage and trash,
          cleaning of the inside and outside of the Squirrel during the time the
          van is rented, parking ticket, moving violations. Unless those costs
          are a result of negligence, in which case user’s liability is not
          limited to the amount of the sec deposit.
        </p>
        <h4 className="content__heading content__heading--medium">Insurance</h4>
        <p className="content__body content__body--no-indent">
          The first $500 in damage will be covered by the guests deposit. Guest
          will pay all damages regardless of fault from the $500 security
          deposit.
        </p>
        <p className="content__body content__body--no-indent">
          Insurance is included in your rental. No additional insurance is
          needed.
        </p>
        <p className="content__body content__body--no-indent">
          Customer is responsible for all damage or repair due to negligence or
          failure to provide maintenance while the renter is responsible for the
          motorhome.{" "}
        </p>
        <p className="content__body content__body--no-indent">
          In case of an accident, we must be notified as soon as possible no
          later than 6 hours from the time of the event. A copy of the full
          police report is required if applicable to the event. If a police
          report is not needed, a full written accident report will be filled
          out at the time of return. Failure to comply will result in the
          forfeiture of your security deposit.
        </p>
        <p className="content__body content__body--no-indent">
          A $500 security and damage deposit is required before departure and is
          charged to your credit card and held by us. This deposit covers any
          damage to the Squirrel motorhome and the furniture, fixtures, and
          equipment. During the rental period, regardless of fault. The deposit,
          or unused portion, is refundable within 7 days after the completion of
          your use of your Squirrel.
        </p>
        <p className="content__body content__body--no-indent">
          We have the right to apply the security deposit to any amounts owed by
          renter to Squirrel, but The $500 security deposit does not cover any
          additional charges for fuel, motor oil, water, coolant, “consumables”,
          additional mileage, additional days, parking tickets, traffic tickets
          or other fines incurred, replacing damaged tires. This deposit also
          covers all costs and our lost time ($75/hr), including pre-and
          post-judgment attorney’s, legal &amp; collection fees we incur
          collecting payment from you or otherwise enforcing our rights under
          this contract. The deposit may be held until the completion of any
          legal or collection action. The deposit, or unused portion, is
          refundable within 7 days after the completion of your use of your
          Squirrel.
        </p>
        <p className="content__body content__body--no-indent">
          If during your use of Squirrel the $500 security and damage deposit is
          ever reduced you authorize us to charge your credit card to the
          Security Deposit always remains at $500.
        </p>
        <p className="content__body content__body--no-indent">
          All damaged tires must be reported to Squirrel customer care. Squirrel
          customer care will have the tire replaced within 4hours at the guest’s
          expense.
        </p>
        <p className="content__body content__body--no-indent">
          During the trip we may use your Security Deposit to pay monies owed GS
          VL, INC. Once the debit is made, the guest's credit card will be
          charged in order to replenish the security deposit so the balance
          stays at $500
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Cancellations
        </h3>
        <p className="content__body content__body--no-indent">
          Renter is entitled to a full refund if reservation is cancelled 24
          prior to reservation start date.
        </p>
        <p className="content__body content__body--no-indent">
          Renter will be charged for one nights rent of $149 if reservation is
          cancelled less than 24 hours prior to reservation.
        </p>
        <p className="content__body content__body--no-indent">
          The cancelation fee of $149 will be deducted from the $500 holding
          deposit.
        </p>
        <p className="content__body content__body--no-indent">
          U.S. military service members are eligible to receive a full refund
          regardless of the cancellation policy if their cancellation is due to
          an active deployment or other military-issued order (Company reserves
          the right to ask for supporting documentation). From time to time, at
          Company’s sole discretion, Company may offer refunds outside of the
          cancellation policy.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Responsibilities Regarding Damages, Loss or Mechanical Issues
        </h3>
        <p className="content__body content__body--no-indent">
          Renter is liable damage resulting from negligence, carelessness or
          inattentiveness on your behalf is your responsibility. This includes
          all off road damage, damage to the undercarriage, damage to or below
          the bumper line, any damage while intoxicated or while committing a
          crime or driving illegally. Negligence includes interior damage and
          damage to the body of the van.
        </p>
        <p className="content__body content__body--no-indent">
          Renter will be solely responsible for the condition of the RV which
          includes both the full interior of the RV and any and all parts of the
          exterior of the RV during the rental period and the condition that the
          RV is returned in to GS Vanlife, Inc. Renters (and not Company) will
          be held liable for any and all damages to the RV that occur during the
          rental period, provided that damages not caused by renter negligence
          shall be capped at $500. Further, Renters will be held liable for any
          damage that cannot be proven to have existed prior to the rental
          period. All Renters agree to assist Company in the settlement of
          security deposit claims and dispute resolution.
        </p>
        <p className="content__body content__body--no-indent">
          The renter is responsible for checking the engine oil, fluids, and
          coolant levels at each refueling, as well as reporting any mechanical
          failures immediately.
        </p>
        <p className="content__body content__body--no-indent">
          We are not liable for renter’s breakdowns that may happen as a result
          of improper maintenance to engine oil, fluids, and coolant levels as
          well as not reporting any mechanical issue immediately.
        </p>
        <p className="content__body content__body--no-indent">
          Renter is liable for any damage or injury caused by renters failure to
          follow standard maintenance procedures.
        </p>
        <p className="content__body content__body--no-indent">
          The costs of these charges is the sole responsibility of the guest and
          is not paid for by the holding deposit.
        </p>
        <h4 className="content__heading content__heading--medium">
          Loss of use
        </h4>
        <p className="content__body content__body--no-indent">
          The causes listed below are fully the responsibility of the renter and
          will be charged to the renter. 1) Return of the motorhome by the
          renter past or before the contracted return date and/or time, 2)
          Repairs from damage to the motor home that are the responsibility of
          the renter. 3) Any other criminal or misdemeanor action by the renter
          that renders the motorhome unavailable for use. The rate charged will
          be our normal daily peak rental rate (complete day) for the period of
          lost use. Each day of lost use equals 4 hrs labor on the repair
          invoice/estimate. Renter authorizes GS Vanlife to charge their credit
          card for these charges.
        </p>
        <h4 className="content__heading content__heading--medium">
          Beyond GS Vanlife's Inc.’s control.
        </h4>
        <p className="content__body content__body--no-indent">
          Maintenance expense reimbursement policy is available from GS Vanlife
          Inc.
        </p>
        <p className="content__body">
          In the event the Squirrel is broken down and in repair for 3 hours or
          more, through no fault of the customer, our responsibility to the
          customer is limited to refund of daily rate or portion thereof. Or, GS
          Vanlife Inc can opt to replace the Squirrel.
        </p>
        <p className="content__body">
          In the case of repair. Customer agrees to make the Squirrel available
          to designated Squirrel mechanic or repairmen.
        </p>
        <p className="content__body content__body--no-indent">
          All damage to the vehicle beyond anything that can be cleaned by
          common household products. For this damage the most a renter can be
          charged for damages is $500 unless this damage is caused by renter
          negligence. This includes:
        </p>
        <ul className="content__body">
          <li>
            Loss of use of the vehicle while it is being repaired for damage
            caused by renter
          </li>
          <li>
            Diminution of the vehicle’s value caused by damage to it or repair
            of it,
          </li>
          <li>
            The diminution in value charge is 2% of the value of the motor home.
            The value is listed on the “Insurance Binder / Endorsement Request
            Form” page of this Rental Contract.
          </li>
          <li>
            All damage or loss caused by blowouts, punctures or other road
            damage, unless the damage is caused by a “loss” that is a collision
            determined not to be renter fault
          </li>
          <li>Missing or damaged furniture, fixtures and equipment,</li>
          <li>Anything broken to the interior or exterior of the squirrel</li>
          <li>
            Damage to the Squirrel’s exterior. This can include but is not
            limited to scratches, dings, dents, and /or damage.
          </li>
          <li>
            Our administrative fees connected with any damage claim, regardless
            of whether you are at fault. These fees are:
          </li>
        </ul>
        <table className="content__table">
          <tr>
            <th>Amount of Damage</th>
            <th>Administrative Fee (Cal. Civil Code 1936 (b)(6))</th>
          </tr>
          <tr>
            <td>Under $100</td>
            <td>None</td>
          </tr>
          <tr>
            <td>$100 to $500</td>
            <td>$100</td>
          </tr>
          <tr>
            <td>$501 to $1,500</td>
            <td>$250 (if due to renter negligence)</td>
          </tr>
          <tr>
            <td>Over $1,500</td>
            <td>$350(if due to renter negligence)</td>
          </tr>
        </table>
        <p className="content__body content__body--no-indent">
          If damages exceed $500. The $500 holding deposit will be applied to
          those
        </p>
        <p className="content__body content__body--no-indent">
          THEFT &amp; VANDALISM: Up to $500, you are responsible for loss due to
          theft or vandalism, or attempted theft or vandalism, of the vehicle
          and all damage due to vandalism that occurs in connection with a
          theft. Unless as determined by GS VL, INC that the loss or damage from
          theft or vandalism was a direct result of renter’s negligence or
          criminal activity, then the renter will be responsible for the total
          of those costs.
        </p>
        <p className="content__body content__body--no-indent">
          RENTER MUST INSPECT EXTERIOR AND INTERIOR OF RENTAL FOR ANY DAMAGE
          PRIOR TO TAKING Occupancy. Any damage while renting will be billed to
          the renter. Each renter must complete and sign the Inventory Checklist
          prior to the rental starting. If the Inventory Checklist is not
          completed and signed within 2 hours of the rental beginning then it
          will be agreed that the renter acknowledges there is no damage,
          nothing is missing, and everything in the Squirrel is working order.
          (this ultimately needs to be done all through the app - no calls etc)
        </p>
        <h4 className="content__heading content__heading--medium">
          Charges, Cancelations and Charge Backs
        </h4>
        <p className="content__body content__body--no-indent">
          In the event that a Renter initiates a chargeback with their credit
          card company, for either rental fees or a security deposit, Company
          will inform the Owner that a chargeback has been initiated. If Company
          deems that the chargeback is not warranted, Company will use
          commercially reasonable efforts to dispute the validity of the
          chargeback on the Owner’s behalf. Owner agrees to cooperate with
          Company and to provide any information that may be reasonably
          requested by Company in its investigation. If you are a Owner, you
          authorize Company to share information about a chargeback with the
          Renter, the Renter’s financial institution, and Owner’s financial
          institution in order to investigate or mediate a chargeback. In the
          event that a chargeback dispute is lost, and funds are debited from
          Company’s account, Owner authorizes Company to, without notice,
          recapture such amount from Owner’s bank account or to withhold such
          amount from any payment due to Owner now or in the future. Owner
          acknowledges that chargeback decisions are made by the applicable
          issuing bank, card networks, or NACHA and all judgments as to the
          validity of the chargeback are made at the sole discretion of the
          applicable issuing bank, Card Networks, or NACHA.
        </p>
        <h4 className="content__heading content__heading--medium">Generator</h4>
        <p className="content__body content__body--no-indent">
          Check consumable console regularly for remaining battery power The
          generator releases Carbon Monoxide which is a toxic clear gas that has
          no smell. (Disclaimer here____)
        </p>
        <h4 className="content__heading content__heading--medium">
          Air conditioning
        </h4>
        <p className="content__body content__body--no-indent">
          Check consumable console regularly for remaining battery power
        </p>
        <h4 className="content__heading content__heading--medium">
          Refrigerator
        </h4>
        <p className="content__body content__body--no-indent">
          Check consumable console regularly for remaining battery power
        </p>
        <h4 className="content__heading content__heading--medium">
          DRY CAMPING
        </h4>
        <p className="content__body content__body--no-indent">
          When a Renter is Dry Camping (not connected to an external power
          source), and is relying solely on the battery for their power source,
          problems may arise due to the battery going low. This may cause the
          appliances to malfunction or to not work at all. GS Vanlife Inc is not
          responsible for, nor will give any reimbursements for dry camping
          malfunctions or issues.
        </p>
        <h4 className="content__heading content__heading--medium">Smoking</h4>
        <p className="content__body content__body--no-indent">
          There is no smoking of any kind or burning of incense / candles
          permitted in the Squirrel. An automatic $500 “fumigation fee” will be
          deducted for a Squirrel being returned having any evidence of smoke or
          smoking. This charge is at the sole discretion of GS Vanlife, Inc. Our
          main concern is for the health of subsequent renters.
        </p>
        <p className="content__heading">
          We enforce a strict NO SMOKING policy.
        </p>
        <h4 className="content__heading content__heading--medium">
          Restricted use
        </h4>
        <ul className="content__body">
          <li>
            All desert areas during hot periods, including Death Valley. During
            these periods, many areas are not habitable and could pose a danger
            to humans.
          </li>
          <li>Gatherings or events, which could pose a danger to humans.</li>
          <li>Non-Public roads, unpaved, dirt roads, and “off-road” areas</li>
        </ul>
        <p className="content__body content__body--no-indent">
          Renter is liable for all damages to vehicle, tires, batteries, towing
          charges and other expenses as a result of a breakdown associated with
          operating in these areas. Security &amp; Damage Deposit will be
          forfeited if violated.
        </p>
        <p className="content__body content__body--no-indent">
          Renter is prohibited in use of the motorhome associated with any
          illegal or unsafe purpose, including consuming alcohol, drugs, and
          medication that impairs one judgment, while driving.
        </p>
        <p className="content__body content__body--no-indent">
          Security &amp; Damage Deposit will be forfeited if any portion of this
          is violated.
        </p>
        <h4 className="content__heading content__heading--medium">
          Traffic Violation
        </h4>
        <p className="content__body content__body--no-indent">
          Guest will be responsible for all moving and parking violations.
        </p>
        <h4 className="content__heading content__heading--medium">
          Sanitation dump
        </h4>
        <p className="content__body content__body--no-indent">
          GS Vanlife Inc, will empty the sewage cartridge(s) when the motorhome
          is returned as part of the cleaning.
        </p>
        <p className="content__body content__body--no-indent">
          Should the RV be returned with holding tanks clogged, a fee of $500
          will be charged. In addition, the renter will be responsible for any
          tickets or fines levied in conjunction with waste disposal. Toilet
          tank catalyst is provided to prevent this.
        </p>
        <h4 className="content__heading content__heading--medium">
          Cleaning charges
        </h4>
        <p className="content__body content__body--no-indent">
          A fee to clean the motorhome upon return has been included in this
          contract. If the motorhome required Any damage that can't be cleaned
          by surface cleaning and washing will be deducted from your $500
          holding deposit
        </p>
        <h4 className="content__heading content__heading--medium">Fuel</h4>
        <p className="content__body content__body--no-indent">
          Fuel and propane tanks are released full and must be returned full.
        </p>
        <p className="content__body content__body--no-indent">
          No warranties are made regarding fuel tank capacity or fuel mileage.
        </p>
        <p className="content__body content__body--no-indent">
          All gasoline or propane refueling during the trip is at customer’s
          expense.
        </p>
        <p className="content__body content__body--no-indent">
          The Squirrel is provided with fuel tank touching the full marker.
          Renter agrees to return the Squirrel with fuel tank at the same level;
          otherwise, the refill charge is double the actual cost to squirrel
          offilling up the tank at the most convenient retail gas station in
          squirrels sole discretion.
        </p>
        <p className="content__body content__body--no-indent">
          ADD SOMETHING ABOUT CARSEATS AND CHILDREN and their rules BEING
          renters responsibility.
        </p>
        <p className="content__body content__body--no-indent">
          Any windshield or window damage will result in the replacement of the
          windshield/window. This is for safety considerations. A designated
          Squirrel mechanic, repairmen, or service provider will make the
          repair.
        </p>
        <h4 className="content__heading content__heading--medium">
          Towing of trailers is not allowed
        </h4>
        <p className="content__body content__body--no-indent">
          The installation of a trailer hitch to tow anything is strictly
          prohibited.
        </p>
        <h4 className="content__heading content__heading--medium">
          Roof Racks
        </h4>
        <p className="content__body content__body--no-indent">
          The installation of a roof rack is strictly prohibited.
        </p>
        <h4 className="content__heading content__heading--medium">
          Refer a Friend Program
        </h4>
        <p className="content__body content__body--no-indent">
          Under the Company’s Refer a Friend Program (the “Program”), Renters
          may invite prospective renters who are not currently nor have never
          been a renter to rent a Squirrel. For each referral that turns into a
          reservation the Squirrel guest will receive a $25 credit toward their
          vehicle rental.
        </p>
        <p className="content__body content__body--no-indent">
          A Referral Bonus can be earned as follows: (add here)
        </p>
        <p className="content__body content__body--no-indent">
          Once a reservation has been verified and the other conditions for
          payment (specified below) have occurred, the Referring Renter is
          eligible to receive a one-time “Referral Bonus” in an amount
          established by the Company from time to time in its sole discretion
          (currently $25).
        </p>
        <p className="content__body content__body--no-indent">
          For all Program Participants. We may suspend, terminate or change the
          terms and requirements of the Program at any time and for any reason.
          A Referral Bonus already earned in accordance with the terms of the
          Program before such change, suspension or termination, will be
          honored.
        </p>
        <p className="content__body content__body--no-indent">
          Program Participants have to be at least 21 years old. Employees,
          contractors and other personnel of the Company or its affiliates (as
          well as their immediate family members) are excluded from
          participation. Program Participants need to be natural persons.
          Referring Owners and Referred Owners must at all times be different
          people and unrelated. Referring Renters and Referred Renters must at
          all times be different people and unrelated. Participation in Program
          is void wherever prohibited under applicable law.
        </p>
        <p className="content__body content__body--no-indent">
          A determination on whether or not Participants have qualified for a
          Referral Bonus will be made by the Company in its sole and absolute
          discretion.
        </p>
        <p className="content__body content__body--no-indent">
          The Referring Renter can refer an unlimited about of times.. The
          Program can only be used for personal, non-commercial purposes.
        </p>
        <p className="content__body content__body--no-indent">
          The Program Participants will be responsible for any taxes or charges
          that may arise due to the earning and payout of the Referral Bonus.
        </p>
        <p className="content__body content__body--no-indent">
          Referring Renters are prohibited from forwarding, sharing, or
          transmitting Referral Links in violation of applicable anti-spam laws.
          Each Referring Renter will indemnify and hold the GS Vanlife Inc
          harmless, from and against any claims that may arise from any unlawful
          forwarding, sharing, or transmitting of the Referral Link.
        </p>
        <p className="content__body content__body--no-indent">
          With respect to the Program, Program Participants will: (i) not
          directly or indirectly (a) offer, promise, or give to any third party
          (including any governmental official or political party('s official,
          representative or candidate)), or (b) seek, accept, or get promised
          for itself of for another party, any gift, payment, reward,
          consideration, or benefit of any kind that would or could be construed
          as bribery or an illegal or corrupt practice, and (ii) comply with all
          applicable laws governing anti-bribery and corrupt gifts and practices
          (including the U.S. Foreign Corrupt Practices Act).
        </p>
        <p className="content__body content__body--no-indent">
          The Company retains the right to, at its sole discretion, review a
          Program Participant’s compliance with the terms of the Program. We
          retain the right to investigate participation in the Program for any
          fraudulent activities and take any measures to end them. Referral
          Bonuses earned through fraudulent activities or activities in
          violation of these terms will be null and void and repayment may be
          requested by the Company (or, if applicable, applied as an offset or
          deduction against a Participant’s Member account).
        </p>
        <h4 className="content__heading content__heading--medium">
          Act of God
        </h4>
        <p className="content__body content__body--no-indent">
          GS Vanlife Inc is not responsible for loss, damage or changes to the
          vehicle, delays or changes which happen because of civil conflict,
          corporate dilemmas including air traffic, terrorist activity, natural
          or nuclear disasters, fire or unfavorable weather conditions,
          cancelled flights, or airline bankruptcies.
        </p>
        <p className="content__body content__body--no-indent content__body--bold">
          NOTICE: These Costs and Policies automatically constitute a portion of
          any and all Rental Contracts entered into by GS Vanlife Inc, Inc. and
          renter(s), and are binding on both parties. All other materials given
          the renter during the rental period are included as part of this
          contract. Signature warrants that renter accepts and understands all
          conditions herein.
        </p>
        <h2 className="content__heading content__heading--medium content__heading--bottom-margin">
          III. MISCELLANEOUS
        </h2>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Disclaimer of Warranties
        </h3>
        <p className="content__body content__body--no-indent">
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY
          HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
          PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
          WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO
          YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
          LINKED TO IT.
        </p>
        <p className="content__body content__body--no-indent">
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT
          LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
          THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
          RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE
          FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET
          YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p className="content__body content__body--no-indent">
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p className="content__body content__body--no-indent">
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Limitation on Liability
        </h3>
        <p className="content__body content__body--no-indent">
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          CONSULTANTS, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF
          ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
          YOUR USE, OR INABILITY TO USE, THE APP OR THE WEBSITE, ANY WEBSITES
          LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES,
          INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
          PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO PERSONAL INJURY, PAIN
          AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
          LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
          GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
          NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE
          FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
          LIMITED UNDER APPLICABLE LAW.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Indemnification
        </h3>
        <p className="content__body content__body--no-indent">
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys’ fees) arising out
          of or relating to your violation of these Terms of Use or your use of
          the Website, including, but not limited to, your User Contributions,
          any use of the Website’s content, services and products other than as
          expressly authorized in these Terms of Use, or your use of any
          information obtained from the Website.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Governing Law and Jurisdiction
        </h3>
        <p className="content__body content__body--no-indent">
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          Delaware without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Delaware or any other
          jurisdiction).
        </p>
        <p className="content__body content__body--no-indent">
          Any legal suit, action or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          Delaware, although we retain the right to bring any suit, action, or
          proceeding against you for breach of these Terms of Use in your
          country of residence or any other relevant country. You waive any and
          all objections to the exercise of jurisdiction over you by such courts
          and to venue in such courts.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Arbitration
        </h3>
        <p className="content__body content__body--no-indent">
          At Company’s sole discretion, it may require You to submit any
          disputes arising from these Terms of Use or use of the Website,
          including disputes arising from or concerning their interpretation,
          violation, invalidity, non-performance or termination, to final and
          binding arbitration under the Rules of Arbitration of the American
          Arbitration Association applying Delaware law.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Limitation on Time to File Claims
        </h3>
        <p className="content__body content__body--no-indent">
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Waiver and Severability
        </h3>
        <p className="content__body content__body--no-indent">
          No waiver by the Company of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </p>
        <p className="content__body content__body--no-indent">
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Entire Agreement
        </h3>
        <p className="content__body content__body--no-indent">
          The Terms of Use, our Privacy Policy and the other transaction
          agreements you enter into upon purchase of our Services collectively
          constitute the sole and entire agreement between you and us regarding
          the Website and such Services and supersede all prior and
          contemporaneous understandings, agreements, representations, and
          warranties, both written and oral, regarding the Website and such
          Services.
        </p>
        <h3 className="content__heading content__heading--bold content__heading--medium">
          Your Comments and Concerns
        </h3>
        <p className="content__body content__body--no-indent">
          This website is operated by GS VanLife, Inc. All feedback, comments,
          requests for technical support, and other communications relating to
          the Website should be directed to:{" "}
          <a href="mailto:info@nicesquirrel.com">info@nicesquirrel.com</a>.
        </p>
      </section>
    </Layout>
  )
}
